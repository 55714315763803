import React, { useState, useEffect } from "react";
import { graphql } from "gatsby";
import get from "lodash/get";
import PropTypes from "prop-types";
import { Button } from "antd";
import { useLazyQuery, useQuery } from "@apollo/react-hooks";

import { ContentBlock, TextBlock } from "../../components/Blocks";
import seoData from "../../components/SEO/data";
import Pages from "../../components/Pages";
import { ContentList } from "../../components/Lists";
import { removePreloader, makeUrl, removeEmptyKeys } from "../../helpers";
import Breadcrumbs from "../../components/Layout/Breadcrumbs";
import { Share } from "../../widgets";
import { useThemeContext } from "../../components/Layout/ThemeContext";
import { SERAFIM_PROJECT_MATERIALS, SERAFIM_PROJECT_MATERIALS_MAX_UPDATE } from "../../queries/queries.graphql";

export const query = graphql`
	query serafimProjectMaterialsQuery($slug: String!) {
		hasura {
			...SerafimProjectMaterials
		}
	}
`;

const MATERIAL_SLUG_BY_TYPE = {
	news: makeUrl.news,
	media_article: makeUrl.mediaArticle,
};

export default function SerafimProjectMaterials({ data, pageContext }) {
	const { theme } = useThemeContext();
	const hasuraData = get(data, "hasura", {});
	const url = "materials";
	const limit = 6;

	const [materials, setMaterials] = useState(get(hasuraData, "materials", []));
	const [offset, setOffset] = useState(limit);

	const seo = get(hasuraData, "page_settings[0]", {});
	const maxUpdated = get(hasuraData, "materials_aggregate.aggregate.max.updated_at", new Date());

	const { loading: maxUpdateLoading, error: maxUpdateError, data: maxUpdateData } = useQuery(
		SERAFIM_PROJECT_MATERIALS_MAX_UPDATE,
		{
			variables: {
				slug: pageContext.slug,
			},
			fetchPolicy: "network-only",
			partialRefetch: true,
		});

	const [loadInitialUpdatedMaterials, { called: initialCalled, loading: loadingInititalMaterials, error: initialError, data: initialMaterialsData }] = useLazyQuery(
		SERAFIM_PROJECT_MATERIALS,
		{
			variables: {
				limit,
				slug: pageContext.slug,
			},
			fetchPolicy: "network-only",
			partialRefetch: true,
		});

	const [loadMaterials, { called, loading, error, data: materialsData }] = useLazyQuery(
		SERAFIM_PROJECT_MATERIALS,
		{
			variables: {
				limit,
				slug: pageContext.slug,
			},
			fetchPolicy: "network-only",
			partialRefetch: true,
		});

	useEffect(() => {
		if (maxUpdateData && !maxUpdateError) {
			const currentMaxUpdated = new Date(get(maxUpdateData, "materials_aggregate.aggregate.max.updated_at", new Date()));
			const currentCount = get(maxUpdateData, "materials_aggregate.aggregate.count", "");

			if (+currentMaxUpdated !== +maxUpdated) {
				loadInitialUpdatedMaterials({
					variables: {
						offset: 0,
					},
				});
			} else {
				removePreloader(true, 800);
				setOffset(currentCount <= materials.length ? null : offset);
			}
		} else if (maxUpdateError) {
			console.error("Invalid load SerafimProjectMaterialsMaxUpdate", { maxUpdateError });
			removePreloader(true, 800);
		}
	}, [maxUpdateLoading]);

	useEffect(() => {
		if (initialCalled && !initialError && !loadingInititalMaterials) {
			const fetchedMaterials = get(initialMaterialsData, "materials", []);

			setMaterials(fetchedMaterials);

			setOffset(fetchedMaterials.length < limit ? null : offset);
		}
		removePreloader(!loading || error);
	}, [loadingInititalMaterials]);

	useEffect(() => {
		if (called && !error && !loading) {
			const fetchedMaterials = get(materialsData, "materials", []);

			setMaterials([...materials, ...fetchedMaterials]);

			setOffset(fetchedMaterials.length < limit ? null : offset + limit);
		}
		removePreloader(!loading || error);
	}, [loading]);

	const projectMaterials = materials.map(item => {
		const title = get(item, "page_title_full", get(item, "page_title_short:", ""));
		const image = get(item, "main_image.src", get(item, "main_image_mobile.src", ""));
		const slug = MATERIAL_SLUG_BY_TYPE[item.type](item);

		return {
			title,
			image,
			slug,
		};
	});

	const disabled = maxUpdateLoading || loadingInititalMaterials || loading || !offset;

	return (
		<Pages entity={seo || get(seoData, "videos_page", {})} url={url}>
			<ContentBlock key={"breadcrumbs"}>
				<div className="container">
					<Breadcrumbs
						currentLabel={"Все материалы"}
						pageContext={
							{
								item: {
									title_full: "Гуманитарные проекты",
									slug: "serafim-project",
								},
								item1: {
									title_full: get(pageContext, "title_full", ""),
									slug: get(pageContext, "slug", ""),
								},
							}
						}
					/>
				</div>
			</ContentBlock>
			<ContentBlock mount={projectMaterials && projectMaterials.length} key={"main-content"}>
				<div className={"container"}>
					<TextBlock title={"Все материалы проекта"} className={"pb-0"} />
					<ContentList
						items={projectMaterials}
						className={"col-12 col-md-4"}
						style={{
							width: "100%",
							height: "350px",
							objectFit: "cover",
						}}
					/>
					<div className="row justify-content-center mb-5">
						<Button
							disabled={disabled}
							onClick={() => loadMaterials({
								variables: {
									offset,
								},
							})}
							className={"d-noscript-none"}
							style={removeEmptyKeys({
								fontFamily: theme.fontFamily,
								fontSize: theme.fontSize.text,
								lineHeight: theme.lineHeight.text,
								color: theme.color.body,
								backgroundColor: theme.color.text,
							})}
						>
							Посмотреть ещё
						</Button>
					</div>
				</div>
			</ContentBlock>
			<Share url={url} pageTitleShort={"Видеотека"} />
		</Pages>
	);
}

SerafimProjectMaterials.propTypes = {
	data: PropTypes.object,
	pageContext: PropTypes.object,
};

SerafimProjectMaterials.defaultProps = {
	pageContext: {},
	data: {},
};
